import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {
  CrmPersonSearchService,
  eCaseAnimations,
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal,
  LoginAuthenticationService,
  PendingChangesGuard
} from 'synto-common';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {NotificationSenderNgxMatDateAdapter} from './notification-sender-ngxmatdate-adapter';
import {CrmPersonSearchDialogComponent} from "../crm-person-search-dialog/crm-person-search-dialog.component";


@Component({
  selector: 'ecase-notification-sender',
  templateUrl: './notification-sender.component.html',
  styleUrls: ['./notification-sender.component.scss'],
  animations: eCaseAnimations,
  providers: [
    NotificationSenderNgxMatDateAdapter, {
      provide: NgxMatDateAdapter,
      useClass: NotificationSenderNgxMatDateAdapter
    }
  ]
})
export class NotificationSenderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() emailTemplates: any[];
  @Input() accessLocation;

  @Input() set notificationSenderInput(notificationSenderInput: NotificationSenderInput) {
    this._notificationSenderInput = notificationSenderInput;
    this.buildForm();
  }

  @Output() templateChange = new EventEmitter<NotificationSenderInput>();
  @Output() formValueChange = new EventEmitter<NotificationSenderInput>();
  @Output() isFormValidOnChange = new EventEmitter<boolean>();
  selectedLang;
  subscription: Subscription;
  valueChangeSubscriber: Subscription;
  notificationSenderForm: FormGroup;
  modules = {};
  emailPattern = /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(]?)(\s*;\s*|\s*$))*$/;
  supportedExtensions = ECaseUtilsGlobal.FILE_UPLOADER_SUPPORTED_EXTENSIONS;
  isEditHeader: boolean;
  isEditFooter: boolean;
  _notificationSenderInput: NotificationSenderInput;
  notificationFilesUploaded: File[] = [];
  totalUploadedSize = 0;

  constructor(private formBuilder: FormBuilder, private translateService: TranslateService, public dialog: MatDialog,
              public crmPersonSearchService: CrmPersonSearchService, private pendingChangesGuard: PendingChangesGuard,
              private  eCaseSnackBarService: ECaseSnackBarService, public loginAuthenticationService: LoginAuthenticationService,
              private eCaseHttpService: EcaseHttpService, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe) {
  }

  transformFileSize(size: number): string {
    return this.eCaseNumberFormatterPipe.fileSizeTransform(size, this.selectedLang);
  }

  ngOnInit(): void {
    if (!this.accessLocation) {
      alert('@Input() "accessLocation" variable is undefined for component "DocumentListingComponent". Please pass the value for it before committing your code.');
    }
    this.selectedLang = this.translateService.getDefaultLang();
    this.subscription = this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLang = params.lang;
    });

    if (!this.emailTemplates) {
      this.emailTemplates = [];
    } else {
      this.emailTemplates = ECaseUtils.removeDuplicates(this.emailTemplates, 'id');
      this.emailTemplates.sort((a, b) => {
        const x = ((a.value[this.selectedLang] === null || a.value[this.selectedLang] === undefined) ? '' : a.value[this.selectedLang]).toLowerCase();
        const y = ((b.value[this.selectedLang] === null || b.value[this.selectedLang] === undefined) ? '' : b.value[this.selectedLang]).toLowerCase();
        return x.localeCompare(y);
      });
    }
    setTimeout(() => {
      const documentsAllowedToDownload = this._notificationSenderInput?.notificationDocuments.map((e: any) => e.edmRepositoryDocumentId);
      this.totalUploadedSize = this._notificationSenderInput?.notificationDocuments.map((e: any) => (isNaN(e.fileSize) ? e.size : e.fileSize)).reduce((a, b) => a + b, 0);
      ECaseUtils.setKeyValueInTheSession(this.eCaseHttpService, 'documentsAllowedToDownload', documentsAllowedToDownload).subscribe((_response) => {
        if (_response.status) {
          this.buildForm();
        } else {
          this.eCaseSnackBarService.show('failure', _response.message);
        }
      });
    }, 500);

  }

  parentDivOnClick(): void {
    const element: HTMLElement = document.getElementById('replaceFileUploadInput');
    if (element) {
      element.click();
    }
  }

  onFileInput(event): void {
    let isValid = true;
    for (const file of event.target.files) {
      const reducer = (accumulator, currentValue): boolean => !accumulator ? (accumulator || file.name.endsWith(currentValue)) : accumulator;
      this.totalUploadedSize = this.totalUploadedSize + file.size;
      if (!this.supportedExtensions.reduce(reducer, false)) {
        alert(ECaseUtils.getTranslatedValueFromKey(this.translateService, 'invalidTypeFile'));
        isValid = false;
        break;
      } else if (file.size > (25 * 1048576)) {
        alert(ECaseUtils.getTranslatedValueFromKey(this.translateService, 'invalidSizeFile'));
        isValid = false;
        break;
      } else if (this.totalUploadedSize > (50 * 1048576)) {
        this.totalUploadedSize = this.totalUploadedSize - file.size;
        alert(ECaseUtils.getTranslatedValueFromKey(this.translateService, 'ecase.common.totalUploadedCannotExceed50mb'));
        isValid = false;
        break;
      }
    }
    if (isValid) {
      for (const file of event.target.files) {
        this.notificationFilesUploaded.push(file);
      }
      this.updateNotificationSenderInputObject();
      this.formValueChange.emit(this._notificationSenderInput);
    }
  }

  download(url): void {
    this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + url, {
      'accessLocation' : this.accessLocation
    }, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe({
      next: (response: HttpResponse<Blob>) => {
        ECaseUtils.generateHyperLinkForDownload(response);
      },
      error: (error) => {
        this.eCaseSnackBarService.show('failure', error.errorMessage);
      }
    });
  }

  downloadFileAttachment(item): void {
    saveAs(item, item.name);
  }

  deleteFileAttachment(item): void {
    this.notificationFilesUploaded.splice(this.notificationFilesUploaded.indexOf(item), 1);
    this.updateNotificationSenderInputObject();
    this.formValueChange.emit(this._notificationSenderInput);
  }

  isInstanceOfFile(item): boolean {
    return item instanceof File;
  }

  buildForm(): void {
    const notificationSenderFormObject = {};
    notificationSenderFormObject['crmEmailTemplateId'] = [{
      value: this._notificationSenderInput?.crmEmailTemplateId ? this._notificationSenderInput?.crmEmailTemplateId : -1,
      disabled: this._notificationSenderInput?.isTemplateSelectorDisabled
    }, [Validators.required]];
    notificationSenderFormObject['sendBy'] = [{
      value: this._notificationSenderInput?.sendBy,
      disabled: this._notificationSenderInput?.isSendByDisabled
    }];
    notificationSenderFormObject['sendTo'] = [{
      value: this._notificationSenderInput?.sendTo,
      disabled: false // This was added to fix MTOSYNTOSUP-193 and confirmed by Cindy that this needs to be a global
      // behaviour for all clients this._notificationSenderInput?.isSendToDisabled
    }];
    notificationSenderFormObject['sendDate'] = [{
      value: this._notificationSenderInput?.sendDate ? this._notificationSenderInput?.sendDate : null,
      disabled: this._notificationSenderInput?.isSendDateDisabled
    }, [Validators.required]];
    notificationSenderFormObject['ccEmails'] = [{
      value: this._notificationSenderInput ? ECaseUtils.removeDups(this._notificationSenderInput?.ccEmails).join(';') : '',
      disabled: this._notificationSenderInput?.isCcEmailsDisabled
    }, [Validators.pattern(this.emailPattern)]];
    notificationSenderFormObject['content'] = [{
      value: (this._notificationSenderInput?.content ? this._notificationSenderInput?.content : ''),
      disabled: this._notificationSenderInput?.isContentDisabled
    }, [Validators.required]];
    notificationSenderFormObject['subject'] = [{
      value: this._notificationSenderInput?.subject,
      disabled: this._notificationSenderInput?.isSubjectDisabled
    }, [Validators.required]];
    notificationSenderFormObject['header'] = [{
      value: this._notificationSenderInput?.header,
      disabled: this._notificationSenderInput?.isHeaderDisabled
    }];
    notificationSenderFormObject['footer'] = [{
      value: this._notificationSenderInput?.footer,
      disabled: this._notificationSenderInput?.isFooterDisabled
    }];
    this.notificationFilesUploaded = (this._notificationSenderInput?.notificationDocuments ? this._notificationSenderInput?.notificationDocuments : []);
    notificationSenderFormObject['notificationDocuments'] = this.notificationFilesUploaded;
    notificationSenderFormObject['isNotificationSkipped'] = this._notificationSenderInput?.isNotificationSkipped;
    this.notificationSenderForm = this.formBuilder.group(notificationSenderFormObject);
    this.valueChangeSubscriber = this.notificationSenderForm.valueChanges.subscribe(() => {
      if (this.notificationSenderForm.dirty) {
        this.updateNotificationSenderInputObject();
        this.formValueChange.emit(this._notificationSenderInput);
        this.isFormValidOnChange.emit(this.notificationSenderForm.valid);
        this.pendingChangesGuard.isPristine = false;
      }
    });
    this.getAllInputs();
    this.isFormValidOnChange.emit(this.notificationSenderForm.valid);
  }

  templateChanged(event): void {
    if(!this._notificationSenderInput) {
      this._notificationSenderInput = new NotificationSenderInput(event, "", "", "", "", [],
        "", "", "", "", null, null,
        false, false, false, false,
        false, false, false, false,
        false, true, false, [],
        false);
    }
    this._notificationSenderInput.crmEmailTemplateId = event;
    this.updateNotificationSenderInputObject();
    setTimeout(() => {
      this.templateChange.emit(this._notificationSenderInput);
    }, 300);

  }

  updateForm(event: MatCheckboxChange): void {
    if (event.checked) {
      this.notificationSenderForm.clearValidators();
    } else {
      this.buildForm();
    }
    this.notificationSenderForm.updateValueAndValidity();
    this.updateNotificationSenderInputObject();
    this.formValueChange.emit(this._notificationSenderInput);
    this.isFormValidOnChange.emit(event.checked);
  }

  updateNotificationSenderInputObject(): void {
    this._notificationSenderInput.content = this._notificationSenderInput.isContentDisabled ? this.notificationSenderForm
      .controls['content'].value : this.notificationSenderForm.value.content;
    this._notificationSenderInput.subject = this._notificationSenderInput.isSubjectDisabled ? this.notificationSenderForm
      .controls['subject'].value : this.notificationSenderForm.value.subject;
    this._notificationSenderInput.ccEmails = (this._notificationSenderInput.isCcEmailsDisabled ? this.notificationSenderForm
      .controls['ccEmails'].value.split(';') : this.notificationSenderForm.value.ccEmails.split(';'));
    this._notificationSenderInput.footer = this._notificationSenderInput.isFooterDisabled ? this.notificationSenderForm
      .controls['footer'].value : this.notificationSenderForm.value.footer;
    this._notificationSenderInput.header = this._notificationSenderInput.isHeaderDisabled ? this.notificationSenderForm
      .controls['header'].value : this.notificationSenderForm.value.header;
    this._notificationSenderInput.crmEmailTemplateId = this._notificationSenderInput.isTemplateSelectorDisabled ? this.notificationSenderForm
      .controls['crmEmailTemplateId'].value : this.notificationSenderForm.value.crmEmailTemplateId;
    this._notificationSenderInput.sendTo = this._notificationSenderInput.isSendToDisabled ? this.notificationSenderForm
      .controls['sendTo'].value : this.notificationSenderForm.value.sendTo;
    this._notificationSenderInput.sendDate = this._notificationSenderInput.isSendDateDisabled ? this.notificationSenderForm
      .controls['sendDate'].value : this.notificationSenderForm.value.sendDate;
    this._notificationSenderInput.sendBy = this._notificationSenderInput.isSendByDisabled ? this.notificationSenderForm
      .controls['sendBy'].value : this.notificationSenderForm.value.sendBy;
    this._notificationSenderInput.isNotificationSkipped = this._notificationSenderInput.isSkipNotificationCheckboxVisible ? this.notificationSenderForm
      .controls['isNotificationSkipped'].value : false;
    this._notificationSenderInput.notificationDocuments = this.notificationFilesUploaded;
  }

  onContentCreated($event, formControlName: string): void {
    const regExp = /[A-Za-z0-9]/i;
    if (!(regExp.test($event.text))) {
      this.notificationSenderForm.controls[formControlName].setErrors({'emptyContent': false});
    } else {
      this.notificationSenderForm.controls[formControlName].setErrors(null);
      this.notificationSenderForm.controls[formControlName].clearValidators();
    }
  }

  getAllInputs(): void {
    setTimeout(() => {
      this.makeQuillEditorContentAccessible('notificationSenderQuillHeaderDiv');
      this.makeQuillEditorContentAccessible('notificationSenderQuillContentDiv');
      this.makeQuillEditorContentAccessible('notificationSenderQuillFooterDiv');
    }, 100);
  }

  makeQuillEditorContentAccessible(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      const documentInputFields = element.getElementsByTagName('input');
      const documentLinks = element.getElementsByClassName('ql-preview');
      for (const documentInputField of documentInputFields[Symbol.iterator]()) {
        if (documentInputField.type === 'text') {
          if (!documentInputField.getAttributeNames().includes('aria-label')) {
            documentInputField.setAttribute('aria-label', 'name');
          }
        }
      }
      for (const documentLink of documentLinks[Symbol.iterator]()) {
        if (!documentLink.getAttributeNames().includes('alt')) {
          documentLink.setAttribute('alt', 'Hyperlink');
          documentLink.setAttribute('aria-label', 'Hyperlink');
        }
      }
    }
  }

  editHeader(): void {
    this.isEditHeader = true;
  }

  editFooter(): void {
    this.isEditFooter = true;
  }

  openCrmSearchDialog(): void {
    this.crmPersonSearchService.isUserSearch = true;
    const dialogRef = this.dialog.open(CrmPersonSearchDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && Array.isArray(result)) {
        if (this.notificationSenderForm.controls['ccEmails'].value) {
          this.notificationSenderForm.controls['ccEmails'].patchValue(this.notificationSenderForm.controls['ccEmails'].value + ' ;' + result.map(e => e.username).join(' ;'));
        } else {
          this.notificationSenderForm.controls['ccEmails'].patchValue(result.map(e => e.username).join(' ;'));
        }
        this.updateNotificationSenderInputObject();
        this.formValueChange.emit(this._notificationSenderInput);
      }
    });
  }

  getTextLength(l): number {
    try {
      return this.notificationSenderForm.controls[l].value ? this.notificationSenderForm.controls[l].value.length : 0;
    } catch (e) {
      return 0;
    }
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.valueChangeSubscriber) {
      this.valueChangeSubscriber.unsubscribe();
    }
    ECaseUtils.removeKeyListFromSession(this.eCaseHttpService, ['documentsAllowedToDownload']).subscribe(() => {
    });
  }

  ngAfterViewInit(): void {
    this.isFormValidOnChange.emit(this.notificationSenderForm.valid);
  }


}

export class NotificationSenderInput {
  crmEmailTemplateId?: number;
  messageType?: any;
  sendBy: string;
  sendTo: string;
  sendDate?: string;
  ccEmails: string[];
  content: string;
  subject: string;
  header: string;
  footer: string;
  minDateForSendDate?: Date;
  maxDateForSendDate?: Date;
  isTemplateSelectorDisabled: boolean;
  isSendByDisabled: boolean;
  isSendDateDisabled: boolean;
  isSendToDisabled: boolean;
  isCcEmailsDisabled: boolean;
  isContentDisabled: boolean;
  isHeaderDisabled: boolean;
  isFooterDisabled: boolean;
  isSubjectDisabled: boolean;
  isSkipNotificationCheckboxVisible: boolean;
  isNotificationSkipped: boolean;
  isFileUploaderDisabled: boolean;
  notificationDocuments: File[];

  constructor(crmEmailTemplateId: number, messageType: any, sendBy: string, sendTo: string, sendDate: string, ccEmails: string[],
              content: string, subject: string, header: string, footer: string, minDateForSendDate: Date, maxDateForSendDate: Date,
              isTemplateSelectorDisabled: boolean, isSendByDisabled: boolean, isSendDateDisabled: boolean, isSendToDisabled: boolean,
              isCcEmailsDisabled: boolean, isContentDisabled: boolean, isHeaderDisabled: boolean, isFooterDisabled: boolean,
              isSubjectDisabled: boolean, isSkipNotificationCheckboxVisible: boolean, isNotificationSkipped: boolean, notificationDocuments: File[],
              isFileUploaderDisabled: boolean) {
    this.crmEmailTemplateId = crmEmailTemplateId;
    this.messageType = messageType;
    this.sendBy = sendBy;
    this.sendTo = sendTo;
    this.sendDate = sendDate;
    this.ccEmails = ccEmails;
    this.content = content;
    this.subject = subject;
    this.header = header;
    this.footer = footer;
    this.minDateForSendDate = minDateForSendDate;
    this.maxDateForSendDate = maxDateForSendDate;
    this.isTemplateSelectorDisabled = isTemplateSelectorDisabled;
    this.isSendByDisabled = isSendByDisabled;
    this.isSendDateDisabled = isSendDateDisabled;
    this.isSendToDisabled = isSendToDisabled;
    this.isCcEmailsDisabled = isCcEmailsDisabled;
    this.isContentDisabled = isContentDisabled;
    this.isHeaderDisabled = isHeaderDisabled;
    this.isFooterDisabled = isFooterDisabled;
    this.isSubjectDisabled = isSubjectDisabled;
    this.isSkipNotificationCheckboxVisible = isSkipNotificationCheckboxVisible;
    this.isNotificationSkipped = isNotificationSkipped;
    this.notificationDocuments = notificationDocuments;
    this.isFileUploaderDisabled = isFileUploaderDisabled;
  }

}
